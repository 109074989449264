import React from 'react';
import { Card, Text } from 'theme-ui';
import Section from '../../components/Section';

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        도움이 필요하시거나 기술적인 문제가 발생했을때 연락주세요.
      </Text>
    </Card>
  </Section>
);

export default Commitment;
